import descomponer_cubo from "./descomponer_cubo.js";
// import header from "./ocultarHeader.js"
import cabecera from "./templates.js";

document.addEventListener("DOMContentLoaded",(e)=>{
    //header();
    descomponer_cubo();
   cabecera(('(min-width:768px)'));

})
